import BaseService from './baseService';

class AuthService extends BaseService {
  loginUrl = '/user/login';

  /**
   * Service đăng nhập
   * @param {*} data
   * @returns
   */
  login(data) {
    return this.httpService.post(this.loginUrl, data);
  }
}

export default new AuthService();
