import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react/dist/iconify.js';

const LoginPasscode = ({
  password,
  dotCls,
  onClickNumber,
  onClear,
  onBack,
  isLoading,
}) => {
  return (
    <div id='password'>
      <div id='password-title'>Nhập mật mã</div>
      <div id='dot' className={dotCls}>
        <div
          id='dot-item'
          className={password[0] || password[0] === 0 ? 'filled' : ''}
        ></div>
        <div
          id='dot-item'
          className={password[1] || password[1] === 0 ? 'filled' : ''}
        ></div>
        <div
          id='dot-item'
          className={password[2] || password[2] === 0 ? 'filled' : ''}
        ></div>
        <div
          id='dot-item'
          className={password[3] || password[3] === 0 ? 'filled' : ''}
        ></div>
      </div>
      <div id='number'>
        <div className='number-item' onClick={() => onClickNumber(1)}>
          1
        </div>
        <div className='number-item' onClick={() => onClickNumber(2)}>
          2
        </div>
        <div className='number-item' onClick={() => onClickNumber(3)}>
          3
        </div>
        <div className='number-item' onClick={() => onClickNumber(4)}>
          4
        </div>
        <div className='number-item' onClick={() => onClickNumber(5)}>
          5
        </div>
        <div className='number-item' onClick={() => onClickNumber(6)}>
          6
        </div>
        <div className='number-item' onClick={() => onClickNumber(7)}>
          7
        </div>
        <div className='number-item' onClick={() => onClickNumber(8)}>
          8
        </div>
        <div className='number-item' onClick={() => onClickNumber(9)}>
          9
        </div>
        <div className='number-item none'></div>
        <div className='number-item' onClick={() => onClickNumber(0)}>
          0
        </div>
        <div className='number-item none' onClick={onClear}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            viewBox='0 0 24 24'
          >
            <path
              fill='#3e3e3e'
              d='M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-3 12.59L17.59 17L14 13.41L10.41 17L9 15.59L12.59 12L9 8.41L10.41 7L14 10.59L17.59 7L19 8.41L15.41 12'
            />
          </svg>
        </div>
      </div>
      <div className='login-loading mt-16'>
        {isLoading ? <Icon icon='line-md:loading-loop' /> : null}
      </div>
      <div className='login-other' onClick={onBack}>
        Đăng nhập tài khoản khác
      </div>
    </div>
  );
};

LoginPasscode.propTypes = {
  password: PropTypes.array,
  dotCls: PropTypes.string,
  onClickNumber: PropTypes.func,
  onClear: PropTypes.func,
  onBack: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default LoginPasscode;
