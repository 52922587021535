import React from 'react';
import SentGiftItem from './SentGiftItem';
import PropTypes from 'prop-types';
import { Skeleton } from '../common';

const SentGift = ({ sent, updateGift, deleteGift, isLoading }) => {
  return isLoading
    ? [0, 1, 2, 3].map((i) => {
        return (
          <div className='tab-content-item seen'>
            <Skeleton
              cls='me-2'
              customStyle={{ width: 45, height: 45, borderRadius: '0.5rem' }}
            />
            <div className='flex flex-col flex-1'>
              <Skeleton
                customStyle={{
                  width: '100%',
                  height: 18,
                  borderRadius: '0.25rem',
                }}
              />
              <Skeleton
                cls='mt-2'
                customStyle={{
                  width: 100,
                  height: 18,
                  borderRadius: '0.25rem',
                }}
              />
            </div>
            <Skeleton
              cls='ms-2'
              customStyle={{ width: 45, height: 45, borderRadius: '0.5rem' }}
            />
          </div>
        );
      })
    : sent && sent.length > 0
    ? sent.map((item) => {
        return (
          <SentGiftItem
            item={item}
            key={item._id}
            updateGift={updateGift}
            deleteGift={deleteGift}
          />
        );
      })
    : null;
};

SentGift.propTypes = {
  sent: PropTypes.array,
  updateGift: PropTypes.func,
  deleteGift: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SentGift;
