import { useEffect, useRef, useState } from 'react';
import { ImageForm, ImageList, ImageSlide, ImageView } from '../components';
import { Icon } from '@iconify/react/dist/iconify.js';
import { uploadFileToFirebase } from '../common/commonFunction';
import imageService from '../services/imageService';

const ImagePage = () => {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listImage, setListImage] = useState([]);
  const [seleted, setSelected] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isSlideshow, setIsSlideshow] = useState(false);

  const inputRef = useRef(null);
  const descRef = useRef(null);

  const handleSelectImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = () => {
    setFilePreview(null);
    inputRef.current.value = null;
  };

  const handleUploadImage = async (img) => {
    setIsLoading(true);
    const fileLink = await uploadFileToFirebase(img ? img : file, 'images');
    const fileLinkOriginal = await uploadFileToFirebase(file, 'images');
    const res = await imageService.post({
      image: fileLink,
      originalImage: fileLinkOriginal,
      caption: descRef.current.value,
    });

    if (res.data.success) {
      setListImage((prev) => {
        return [res.data.data, ...prev];
      });
      setFilePreview(null);
      inputRef.current.value = null;
    }
    setIsLoading(false);
  };

  const loadImage = async () => {
    setIsLoading(true);
    const res = await imageService.get();

    if (res.data.success) {
      setListImage(res.data.data);
    }
    setIsLoading(false);
  };

  const handleClickImage = (img) => {
    setSelected(img);
    setIsShow(true);
  };

  const handleDeleteImg = async (id) => {
    const result = window.confirm('Bạn có chắc muốn xóa ảnh này không?');
    if (result) {
      setIsLoading(true);
      const res = await imageService.delete(id);

      if (res.data.success) {
        setListImage((prev) => {
          return prev.filter((item) => item._id !== id);
        });
        setIsShow(false);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <div id='wrapper'>
      <div className='gift-title'>
        {!filePreview && !isShow ? (
          <div
            className='fixed top-4 left-4 text-3xl'
            onClick={() => setIsSlideshow((prev) => !prev)}
          >
            <Icon
              icon={
                isSlideshow
                  ? 'fluent:arrow-left-12-regular'
                  : 'fluent:slide-play-24-regular'
              }
              color='#ffccd5'
            />
          </div>
        ) : null}
        <div className='gift-title-text'>Ảnh</div>
      </div>
      {filePreview ? (
        <ImageForm
          filePreview={filePreview}
          descRef={descRef}
          isLoading={isLoading}
          handleUploadImage={handleUploadImage}
          handleCancel={handleCancel}
        />
      ) : isShow ? (
        <ImageView
          img={seleted}
          handleClose={() => setIsShow(false)}
          handleDeleteImg={handleDeleteImg}
        />
      ) : isSlideshow ? (
        <ImageSlide lstImage={listImage} />
      ) : (
        <ImageList
          listImage={listImage}
          isLoading={isLoading}
          handleClickImage={handleClickImage}
        />
      )}
      <input
        type='file'
        hidden
        ref={inputRef}
        onChange={handleSelectImage}
        // accept='image/*'
      />
      <div
        className='button-add'
        onClick={() => {
          inputRef.current.click();
        }}
      >
        <Icon icon='tabler:plus' color='#ff91af' />
      </div>
      {/* {isLoading && (
        <div className='loader-wrapper'>
          <span className='loader'></span>
        </div>
      )} */}
    </div>
  );
};

export default ImagePage;
