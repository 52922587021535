import React, { useEffect, useRef, useState } from 'react';
import song from '../assets/music/PleaseTellMyWhy.mp3';

const MusicPage = () => {
  const content =
    'MY BABY LOVE YOU SO MUCH FOREVER YOU AND I !!! I LOVE YOU OH I LOVE YOU SO MUCH FOREVER YOU AND I';

  const [timeouts, setTimeouts] = useState([]);
  const [heart, setHeart] = useState('');

  const audioRef = useRef(null);
  const lyricRef = useRef(null);

  const playAudio = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.play();

    timeouts.forEach((item) => clearTimeout(item));
    setTimeouts([]);

    const timeout = setTimeout(() => {
      applyLyric();
    }, 3000);

    setTimeouts((prev) => {
      return [...prev, timeout];
    });

    lyricRef.current.innerHTML = '';
    setHeart('');
    setTimeout(() => {
      setHeart(` <div class='music-bubbles'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class='music-bubbles-2'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>`);
    }, 1000);
  };

  const applyLyric = () => {
    const length = content.length;
    const sp = 4;
    for (let i = 0; i < length; i += sp) {
      let txt = content.substring(i, i + sp);
      let timeout = setTimeout(function () {
        let span = document.createElement('span');
        span.textContent = txt;
        span.className = 'lyric-fade';
        if (lyricRef && lyricRef.current) {
          lyricRef.current.appendChild(span);
        }
      }, i * 110);
      setTimeouts((prev) => {
        return [...prev, timeout];
      });
    }
  };

  useEffect(() => {
    return () => {
      setTimeouts([]);
    };
  }, []);

  return (
    <div id='wrapper' onClick={playAudio}>
      <div className='fixed w-full h-full flex items-center justify-center text-6xl md:text-7xl lg:text-8xl'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1em'
          height='1em'
          viewBox='0 0 36 36'
        >
          <path
            fill='#a6d388'
            d='M7.681 9.097c1.587-3.151 7.698-1.916 11.958 2.171c2.697 2.586 8.056 1.498 11.498 4.804c3.493 3.354 3.259 9.361-3.053 15.767C23 37 16 37 11.835 33.384c-4.388-3.811-2.476-8.61-4.412-13.585C5.487 14.823 3.1 9.375 7.681 9.097'
          ></path>
          <path
            fill='#662113'
            d='M8.178 9.534c-.43.448-1.114.489-1.527.093c-3.208-3.079-3.918-7.544-3.946-7.776c-.074-.586.348-1.157.939-1.278c.592-.121 1.131.257 1.205.842c.006.05.657 3.997 3.359 6.59c.413.397.4 1.081-.03 1.529'
          ></path>
        </svg>
      </div>

      <div className='lyric' ref={lyricRef}></div>
      <div className='audio'>
        <audio ref={audioRef} controls>
          <source src={song} type='audio/mpeg' />
        </audio>
      </div>
      <div dangerouslySetInnerHTML={{ __html: heart }}></div>
    </div>
  );
};

export default MusicPage;
