// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDhmab2FKubqdLSdgWCf4uQ2GdXeTtuun4',
  authDomain: 'love-8cdad.firebaseapp.com',
  projectId: 'love-8cdad',
  storageBucket: 'love-8cdad.appspot.com',
  messagingSenderId: '108082956787',
  appId: '1:108082956787:web:9207811424edacf11aea86',
  measurementId: 'G-YYB4Y7JZ6M',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
