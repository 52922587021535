import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/reducers/userSlice';
import { Icon } from '@iconify/react/dist/iconify.js';

const SideBar = ({ notSeenGift, onClose, showSideBar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={`${showSideBar ? 'show' : 'hide'}`}>
      <div className='side-bar' onClick={() => onClose()}></div>
      <div className='side-bar-content'>
        <div className='side-bar-nav'>
          <div
            className={`side-bar-item ${
              location.pathname === '/' ? 'active' : ''
            }`}
            onClick={() => {
              navigate('/');
              onClose();
            }}
          >
            <span className='icon'>
              <Icon icon='fluent:home-12-filled' />
            </span>
            <span className='title'>Trang chủ</span>
          </div>
          <div
            className={`side-bar-item ${
              location.pathname === '/music' ? 'active' : ''
            }`}
            onClick={() => {
              navigate('/music');
              onClose();
            }}
          >
            <span className='icon'>
              <Icon icon='fluent:music-note-2-play-20-filled' />
            </span>
            <span className='title'>Nghe nhạc</span>
          </div>
          <div
            className={`side-bar-item ${
              location.pathname === '/gift' ? 'active' : ''
            }`}
            onClick={() => {
              navigate('/gift');
              onClose();
            }}
          >
            {notSeenGift > 0 ? (
              <div className='not-seen'>{notSeenGift}</div>
            ) : null}
            <span className='icon'>
              <Icon icon='fluent:gift-24-filled' />
            </span>
            <span className='title'>Lời chúc</span>
          </div>
          <div
            className={`side-bar-item ${
              location.pathname === '/image' ? 'active' : ''
            }`}
            onClick={() => {
              navigate('/image');
              onClose();
            }}
          >
            <span className='icon'>
              <Icon icon='fluent:camera-20-filled' />
            </span>
            <span className='title'>Ảnh</span>
          </div>
        </div>
        <div className='side-bar-bottom'>
          <div
            className={`side-bar-item ${
              location.pathname === '/setting' ? 'active' : ''
            }`}
            onClick={() => {
              navigate('/setting');
              onClose();
            }}
          >
            <span className='icon'>
              <Icon icon='fluent:settings-20-filled' />
            </span>
            <span className='title'>Thiết lập</span>
          </div>
          <div
            className={`side-bar-item logout`}
            onClick={() => {
              dispatch(logout());
            }}
          >
            <span className='icon'>
              <Icon icon='tabler:logout' />
            </span>
            <span className='title'>Đăng xuất</span>
          </div>
        </div>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  notSeenGift: PropTypes.number,
  onClose: PropTypes.func,
  showSideBar: PropTypes.bool,
};

export default SideBar;
