import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { Icon } from '@iconify/react/dist/iconify.js';

const ImageForm = ({
  filePreview,
  descRef,
  isLoading,
  handleUploadImage,
  handleCancel,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // để tránh lỗi CORS
      image.src = url;
    });

  const getCroppedImg = async () => {
    const image = await createImage(filePreview);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;

    ctx.drawImage(
      image,
      croppedArea.x,
      croppedArea.y,
      croppedArea.width,
      croppedArea.height,
      0,
      0,
      croppedArea.width,
      croppedArea.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = 'croppedImage.png';
        resolve(file);
      }, 'image/png');
    });
  };

  return (
    <div className='preview-image'>
      {/* <img className='preview-img md:mt-4' src={filePreview} alt='Preview' /> */}
      <div className='preview-img md:mt-4'>
        <Cropper
          image={filePreview}
          aspect={1 / 1}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: '400px',
              height: '400px',
              backgroundColor: '#fff',
              borderRadius: '3rem',
            },
          }}
          cropSize={{ width: 400, height: 400 }}
          objectFit='cover'
          showGrid={false}
        />
      </div>
      <input
        className='preview-input'
        ref={descRef}
        maxLength={25}
        placeholder='Thêm tiêu đề ảnh'
      />
      <div className='preview-buttons'>
        <button
          className={`button-upload ${isLoading ? 'loading-btn-round' : ''}`}
          onClick={async () => {
            if (!isLoading) {
              const img = await getCroppedImg();
              handleUploadImage(img);
            }
          }}
        >
          {isLoading ? (
            <Icon icon='line-md:loading-loop' color='#6c757d' />
          ) : (
            <Icon icon='octicon:upload-16' color='#ff91af' />
          )}
        </button>
        <button
          className='button-cancel text-5xl'
          onClick={() => {
            if (!isLoading) {
              handleCancel();
            }
          }}
        >
          <Icon icon='ic:round-close' color='#ff91af' />
        </button>
      </div>
    </div>
  );
};

ImageForm.propTypes = {
  filePreview: PropTypes.string,
  descRef: PropTypes.any,
  isLoading: PropTypes.bool,
  handleUploadImage: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default ImageForm;
