import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import { userSelector } from '../store/reducers/userSlice';
import { MainLayout } from '../components';

const ProtectedRoute = () => {
  const { isAuthenticate } = useSelector(userSelector);

  return isAuthenticate ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to='/login' />
  );
};

export default ProtectedRoute;
