import React from 'react';
import PropTypes from 'prop-types';

const DisplayGift = ({ selectedGift, setShowGift }) => {
  return (
    <div className='gift-create'>
      <div className='outside' onClick={() => setShowGift(false)}></div>
      <div className='display-gift w-80 md:w-1/2 lg:w-1/3'>
        <div className='display-gift-heart-1'></div>
        <div className='display-gift-heart-2'></div>
        <div className='display-gift-heart-3'></div>
        <div className='display-gift-content'>
          <div className='content'>{selectedGift.content}</div>
          <div className='signature'>{selectedGift.signature}</div>
        </div>
        <div className='close-gift' onClick={() => setShowGift(false)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            viewBox='0 0 512 512'
          >
            <path
              fill='#ff91af'
              d='m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z'
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

DisplayGift.propTypes = {
  selectedGift: PropTypes.object,
  setShowGift: PropTypes.func,
};

export default DisplayGift;
