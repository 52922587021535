import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react/dist/iconify.js';
import SettingImage from './SettingImage';
import userService from '../../services/userService';
import { uploadFileToFirebase } from '../../common/commonFunction';
import {
  updateBoy,
  updateGirl,
  updateUser,
} from '../../store/reducers/userSlice';

const SettingAccount = ({ user, couple }) => {
  //#region Hooks
  const userInputRef = useRef(null);

  const [userInfo, setUserInfo] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [newAvt, setNewAvt] = useState(null);
  const [newAvtUrl, setNewAvtUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userClone = JSON.parse(JSON.stringify(user));
    userClone.dateOfBirth = moment(new Date(userClone.dateOfBirth)).format(
      'YYYY-MM-DD'
    );
    setUserInfo(userClone);
  }, [user]);

  const dispatch = useDispatch();
  //#endregion

  //#region Methods/Functions
  /**
   * Thay đổi giá trị các ô input
   * @param {*} e
   */
  const handleChangeInput = (e) => {
    try {
      if (!isLoading) {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Xử lý chọn ảnh
   * @param {*} e
   */
  const handleSelectImage = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Validata dữ liệu trước khi lưu
   * @returns
   */
  const validateData = () => {
    if (!userInfo.username || !userInfo.username.trim()) {
      alert('Tài khoản không được để trống.');
      return false;
    }
    if (!userInfo.fullName || !userInfo.fullName.trim()) {
      alert('Họ và tên không được để trống.');
      return false;
    }
    return true;
  };

  /**
   * Xự kiện bấm nút lưu
   */
  const handleSaveClick = async () => {
    try {
      if (isLoading) {
        return;
      }
      if (validateData()) {
        setIsLoading(true);
        const infoClone = JSON.parse(JSON.stringify(userInfo));
        // Upload file ảnh avatar
        if (newAvt) {
          const avtLink = await uploadFileToFirebase(newAvt, 'avatars');
          infoClone.avatar = avtLink;
        }
        // Gọi service cập nhật thông tin
        const res = await userService.update(infoClone);

        // Cập nhật các thông tin vào store
        if (res && res.data && res.data.success) {
          dispatch(updateUser(res.data.data));
          if (user._id === couple.boy._id) {
            dispatch(updateBoy(res.data.data));
          } else {
            dispatch(updateGirl(res.data.data));
          }
          // Xóa các file cũ đi
          setFilePreview(null);
          setNewAvt(null);
          URL.revokeObjectURL(newAvtUrl);
          setNewAvtUrl(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  //#endregion

  return userInfo ? (
    <div className='settings-area mb-5 relative'>
      {!filePreview ? (
        <>
          <span className='font-semibold text-lg mb-3 text-center'>
            Thông tin cá nhân
          </span>
          <div className='flex flex-col md:flex-row'>
            <div className='avatar w-full md:w-2/5  mb-4 md:mb-0 md:me-4 flex flex-col items-center'>
              <div
                className='mb-3'
                onClick={() => {
                  if (!isLoading) {
                    userInputRef.current.click();
                  }
                }}
              >
                <input
                  type='file'
                  hidden
                  accept='image/*'
                  ref={userInputRef}
                  onChange={handleSelectImage}
                />
                <img
                  src={newAvtUrl ? newAvtUrl : userInfo.avatar}
                  alt='info-avatar'
                  loading='lazy'
                  className='w-48 h-48 mx-auto rounded-full object-cover'
                />
              </div>
              <span className='text-xs font-semibold text-slate-500'>
                Cho phép *.jpeg, *.jpg, *.png, .*gif
              </span>
            </div>
            <div className='info basis-full'>
              <span className='text-sm text-slate-600 mb-1'>Tài khoản</span>
              <input
                className='setting-input mb-3'
                value={userInfo.username}
                name='username'
                onChange={handleChangeInput}
              />
              <span className='text-sm text-slate-600 mb-1'>Họ và tên</span>
              <input
                className='setting-input mb-3'
                value={userInfo.fullName}
                name='fullName'
                onChange={handleChangeInput}
              />
              <span className='text-sm text-slate-600 mb-1'>Ngày sinh</span>
              <input
                type='date'
                name='dateOfBirth'
                className='setting-input mb-3'
                value={userInfo.dateOfBirth}
                onChange={handleChangeInput}
                placeholder='Thời gian gửi'
              />
            </div>
          </div>
          <button
            className={`btn-save mt-2 ${isLoading ? 'btn-loading' : ''}`}
            onClick={handleSaveClick}
          >
            {!isLoading ? (
              'Lưu'
            ) : (
              <Icon
                icon='line-md:loading-loop'
                width='1.75rem'
                height='1.75rem'
              />
            )}
          </button>
        </>
      ) : (
        <SettingImage
          filePreview={filePreview}
          handleCancel={() => {
            setFilePreview(null);
          }}
          handleSave={(avt) => {
            setNewAvt(avt);
            setFilePreview(null);
            if (newAvtUrl) {
              URL.revokeObjectURL(newAvtUrl);
            }
            setNewAvtUrl(URL.createObjectURL(avt));
          }}
        />
      )}
    </div>
  ) : null;
};

SettingAccount.propTypes = {
  user: PropTypes.object,
  couple: PropTypes.object,
};

export default SettingAccount;
