import './App.css';
import { authRoutes, routes } from './router/routes';
import ProtectedRoute from './router/ProtectedRoute';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HttpInterceptor from './services/http/httpInterceptor';

function App() {
  return (
    <HttpInterceptor>
      <Router>
        <Routes>
          {authRoutes.map((r, i) => {
            const Page = r.component;
            return <Route key={i} path={r.path} element={<Page />} />;
          })}
          <Route element={<ProtectedRoute />}>
            {routes.map((route, index) => {
              const Page = route.component;
              return <Route key={index} path={route.path} element={<Page />} />;
            })}
          </Route>
        </Routes>
      </Router>
    </HttpInterceptor>
  );
}

export default App;
