import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import userService from '../../services/userService';
import { Icon } from '@iconify/react/dist/iconify.js';
import { updateCouple } from '../../store/reducers/userSlice';
import { useDispatch } from 'react-redux';

const SettingCouple = ({ couple }) => {
  //#region Hooks
  const [coupleInfo, setCoupleInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const coupleClone = {
      loveDate: moment(new Date(couple.loveDate)).format('YYYY-MM-DD HH:mm:ss'),
      message: couple.message,
      _id: couple._id,
    };
    setCoupleInfo(coupleClone);
  }, [couple]);

  const dispatch = useDispatch();
  //#endregion

  //#region Functions/Methods
  const handleChangeInput = (e) => {
    try {
      if (!isLoading) {
        setCoupleInfo({ ...coupleInfo, [e.target.name]: e.target.value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveClick = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const infoClone = JSON.parse(JSON.stringify(coupleInfo));
      infoClone.loveDate = new Date(infoClone.loveDate);
      // // Upload file ảnh avatar
      // if (newAvt) {
      //   const avtLink = await uploadFileToFirebase(newAvt, 'avatars');
      //   infoClone.avatar = avtLink;
      // }
      // Gọi service cập nhật thông tin
      const res = await userService.updateCouple(infoClone);
      // Cập nhật các thông tin vào store
      if (res && res.data && res.data.success) {
        dispatch(updateCouple(res.data.data));
        // Xóa các file cũ đi
        // setFilePreview(null);
        // setNewAvt(null);
        // URL.revokeObjectURL(newAvtUrl);
        // setNewAvtUrl(null);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  //#endregion

  return coupleInfo ? (
    <div className='settings-area mb-5'>
      <span className='font-semibold text-lg mb-3 text-center'>
        Thông tin cặp đôi
      </span>
      <span className='text-sm text-slate-600 mb-1'>Thông điệp</span>
      <input
        className='setting-input mb-3'
        value={coupleInfo.message}
        name='message'
        onChange={handleChangeInput}
      />
      <span className='text-sm text-slate-600 mb-1'>Ngày yêu</span>
      <input
        type='datetime-local'
        name='loveDate'
        className='setting-input mb-3'
        value={coupleInfo.loveDate}
        onChange={handleChangeInput}
        placeholder='Thời gian gửi'
      />
      <button
        className={`btn-save mt-2 ${isLoading ? 'btn-loading' : ''}`}
        onClick={handleSaveClick}
      >
        {!isLoading ? (
          'Lưu'
        ) : (
          <Icon icon='line-md:loading-loop' width='1.75rem' height='1.75rem' />
        )}
      </button>
    </div>
  ) : null;
};

SettingCouple.propTypes = {
  couple: PropTypes.object,
};

export default SettingCouple;
