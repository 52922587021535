import { createSlice } from '@reduxjs/toolkit';

const giftSlice = createSlice({
  name: 'gift',
  initialState: {
    sent: [],
    receive: [],
    notSeen: 0,
  },
  reducers: {
    setGift(state, action) {
      state.sent = action.payload.sent;
      state.receive = action.payload.receive;
    },
    addGift(state, action) {
      state.sent.unshift(action.payload);
    },
    seenGiftSuccess(state, action) {
      state.receive = state.receive.map((item) => {
        if (item._id === action.payload) {
          item.seen = true;
        }
        return item;
      });
    },
    deleteGiftSuccess(state, action) {
      state.sent = state.sent.filter((item) => item._id !== action.payload);
    },
    updateGiftSuccess(state, action) {
      state.sent = state.sent.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
      state.receive = state.receive.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
    },
    setNotSeen(state, action) {
      state.notSeen = action.payload;
    },
  },
});

const giftReducer = giftSlice.reducer;

export const {
  setGift,
  addGift,
  seenGiftSuccess,
  deleteGiftSuccess,
  updateGiftSuccess,
  setNotSeen,
} = giftSlice.actions;

export const giftSelector = (state) => state.giftReducer;

export default giftReducer;
