import PropTypes from 'prop-types';

const LoginUser = ({ value, onChange, onNext }) => {
  return (
    <div className='login-form'>
      <input
        type='text'
        name='username'
        value={value}
        onChange={onChange}
        placeholder='Tên người dùng'
      />
      <button onClick={onNext}>Tiếp tục</button>
    </div>
  );
};

LoginUser.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};

export default LoginUser;
