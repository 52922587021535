import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/reducers/userSlice';
import { Clock, Info } from '../components';

const HomePage = () => {
  // State
  const [startDate, setStartDate] = useState(new Date());

  // Selector
  const { couple } = useSelector(userSelector);

  // Effect
  useEffect(() => {
    setStartDate(new Date(couple.loveDate));
  }, [couple, couple.loveDate]);

  return (
    <div id='wrapper'>
      <h1 id='title' className='text-3xl'>
        LOVE DAYS
      </h1>
      <Clock startDate={startDate} />
      <Info couple={couple} />
      <div className='footer'>{couple.message}</div>
    </div>
  );
};

export default HomePage;
