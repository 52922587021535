import BaseService from './baseService';

class GiftService extends BaseService {
  giftUrl = '/gift';

  heartUrl = '/gift/heart';

  get() {
    return this.httpService.get(this.giftUrl);
  }

  post(data) {
    return this.httpService.post(this.giftUrl, data);
  }

  put(data) {
    return this.httpService.put(this.giftUrl, data);
  }

  delete(id) {
    return this.httpService.delete(this.giftUrl + '/' + id);
  }

  updateHeart(id) {
    return this.httpService.get(this.heartUrl + '/' + id);
  }
}

export default new GiftService();
