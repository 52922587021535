import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';

const Clock0 = ({ loveDays, loveTime, percent }) => {
  return (
    <div
      id='clock'
      className='w-48 h-48 rounded-full md:w-80 md:h-32 md:flex-col'
    >
      <div className='flex flex-col items-center md:flex-row md:items-end md:mb-1'>
        <div className='date text-6xl md:text-6xl'>{loveDays}</div>
        <span className='text-base md:text-2xl md:ml-2'>days</span>
      </div>
      <div className='time absolute text-2xl md:relative md:bottom-0 md:text-base'>
        {loveTime}
      </div>
      <CircularProgressbar
        className='circular md:hidden'
        value={percent}
        styles={{
          path: {
            stroke: '#ff91af',
            strokeWidth: 3,
          },
          background: {
            fill: '#3e98c7',
          },
          trail: {
            stroke: 'transparent',
          },
        }}
      />
    </div>
  );
};

const Clock1 = ({ count, loveTime }) => {
  return (
    <div className='flex flex-col items-center relative'>
      <div className='flex'>
        <div className='w-24 h-24 count-item text-5xl flex items-center justify-center flex-col'>
          <span className='number'>{count.years}</span>
          <span className='text-base md:text-xl md:ml-2'>years</span>
        </div>
        <div className='w-24 h-24 count-item text-5xl flex items-center justify-center mx-4 flex-col'>
          <span className='number'>{count.months}</span>
          <span className='text-base md:text-xl md:ml-2'>months</span>
        </div>
        <div className='w-24 h-24 count-item text-5xl flex items-center justify-center flex-col'>
          <span className='number'>{count.days}</span>
          <span className='text-base md:text-xl md:ml-2'>days</span>
        </div>
      </div>
      <div className='time absolute text-2xl md:relative md:bottom-0 md:text-base'>
        {loveTime}
      </div>
    </div>
  );
};

const Clock = ({ startDate }) => {
  const [loveDays, setLoveDays] = useState(0);
  const [loveTime, setLoveTime] = useState('...');
  const [percent, setPercent] = useState(0);
  const [clockStyle, setClockStyle] = useState(0);
  const [count, setCount] = useState({
    years: 0,
    months: 0,
    days: 0,
  });

  /**
   * Đếm số ngày
   */
  const countLoveDays = () => {
    const count = Math.floor(
      Math.floor((new Date() - startDate) / 1000) / 60 / 60 / 24
    );

    setLoveDays(count);
  };

  /**
   * Tính thời gian
   */
  const countLoveTime = () => {
    const today = new Date();
    const hrs =
        Math.floor(Math.floor((today - startDate) / 1000) / 60 / 60) % 24,
      min = Math.floor(Math.floor((today - startDate) / 1000) / 60) % 60,
      sec = Math.floor((today - startDate) / 1000) % 60;
    setLoveTime(
      `${hrs > 9 ? hrs : '0' + hrs}:${min > 9 ? min : '0' + min}:${
        sec > 9 ? sec : '0' + sec
      }`
    );
    setPercent(
      ((Math.floor(Math.floor((today - startDate) / 1000)) % (60 * 60 * 24)) /
        (60 * 60 * 24)) *
        100
    );
  };

  const countLove = () => {
    let start = new Date(startDate);
    let end = new Date();

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();
    let hours = end.getHours() - start.getHours();
    let minutes = end.getMinutes() - start.getMinutes();

    // Điều chỉnh nếu phút bị âm
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }

    // Điều chỉnh nếu giờ bị âm
    if (hours < 0) {
      days -= 1;
      hours += 24;
    }

    // Điều chỉnh nếu ngày bị âm
    if (days < 0) {
      months -= 1;
      const previousMonth = new Date(
        end.getFullYear(),
        end.getMonth(),
        0
      ).getDate();
      days += previousMonth;
    }

    // Điều chỉnh nếu tháng bị âm
    if (months < 0) {
      years -= 1;
      months += 12;
    }

    setCount({
      years,
      months,
      days,
    });
  };

  useEffect(() => {
    countLoveDays();
    countLoveTime();
    countLove();
    const intervalId = setInterval(() => {
      countLoveTime();
      countLove();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDate]);

  return (
    <div
      id='clock-box'
      onClick={() =>
        setClockStyle((prev) => {
          return prev === 1 ? 0 : prev + 1;
        })
      }
    >
      {clockStyle === 0 ? (
        <Clock0 loveDays={loveDays} loveTime={loveTime} percent={percent} />
      ) : clockStyle === 1 ? (
        <Clock1 count={count} loveTime={loveTime} />
      ) : null}
    </div>
  );
};

Clock.propTypes = {
  startDate: PropTypes.any,
};

export default Clock;
