import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/reducers/userSlice';
import { SettingAccount, SettingCouple, SettingPartner } from '../components';

const SettingPage = () => {
  const { user, couple } = useSelector(userSelector);

  const [tab, setTab] = useState(1);

  return (
    <div id='wrapper'>
      <div className='gift-title'>
        <div className='gift-title-text'>Thiết lập</div>
      </div>
      <div className='tab-label'>
        <div
          className={`tab-label-item me-0 ${tab === 1 ? 'active' : ''}`}
          onClick={() => setTab(1)}
        >
          Cá nhân
        </div>
        <div
          className={`tab-label-item ${tab === 2 ? 'active' : ''}`}
          onClick={() => setTab(2)}
        >
          {user._id === couple.boy._id ? 'Bạn gái' : 'Bạn trai'}
        </div>
        <div
          className={`tab-label-item ms-0 ${tab === 3 ? 'active' : ''}`}
          onClick={() => setTab(3)}
        >
          Cặp đôi
        </div>
      </div>
      <div className='tab-content-wrapper'>
        {tab === 1 && (
          <div className='tab-content'>
            <SettingAccount user={user} couple={couple} />
          </div>
        )}
        {tab === 2 && (
          <div className='tab-content'>
            <SettingPartner user={user} couple={couple} />
          </div>
        )}
        {tab === 3 && (
          <div className='tab-content'>
            <SettingCouple couple={couple} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingPage;
