import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from '../common/LazyImage';
import { Skeleton } from '../common';

const ImageList = ({ listImage, handleClickImage, isLoading }) => {
  return (
    <div className='list-image'>
      {isLoading
        ? [0, 1, 2, 3, 4, 5].map((item) => {
            return (
              <div className='relative' key={item}>
                <Skeleton
                  cls='image-item'
                  customStyle={{
                    width: '180px',
                    height: '180px',
                    borderRadius: '1rem',
                  }}
                />
                <Skeleton
                  cls='image-caption'
                  customStyle={{ height: '20px' }}
                />
              </div>
            );
          })
        : listImage && listImage.length > 0
        ? listImage.map((item) => {
            return (
              <div
                className='relative'
                key={item._id}
                onClick={() => handleClickImage(item)}
              >
                <LazyImage
                  cls='image-item'
                  loadingCls='loading'
                  src={item.image}
                  alt={item._id}
                  width={180}
                  height={180}
                />
                <span className='image-caption'>{item.caption}</span>
              </div>
            );
          })
        : null}
    </div>
  );
};

ImageList.propTypes = {
  listImage: PropTypes.array,
  isLoading: PropTypes.bool,
  handleClickImage: PropTypes.func,
};

export default ImageList;
