import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../store/reducers/userSlice';
import { giftSelector, setNotSeen } from '../store/reducers/giftSlice';
import moment from 'moment';
import { DisplayGift, GiftForm, ReceiveGift, SentGift } from '../components';
import { Icon } from '@iconify/react/dist/iconify.js';
import giftService from '../services/giftService';

const GiftPage = () => {
  //#region  Hooks
  const [tab, setTab] = useState(1);
  const [newGift, setNewGift] = useState({
    title: '',
    content: '',
    signature: '',
    sendDate: moment(new Date()).format('YYYY-MM-DD'),
  });
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGift, setSelectedGift] = useState({});
  const [showGift, setShowGift] = useState(false);
  const [editMode, setEditMode] = useState(1);
  const [sent, setSent] = useState([]);
  const [receive, setReceive] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const dispatch = useDispatch();

  const { user, couple } = useSelector(userSelector);
  const { notSeen } = useSelector(giftSelector);

  useEffect(() => {
    getData();
  }, []);
  //#endregion

  //#region Methods/Functions
  const getData = async () => {
    setIsLoadingData(true);
    const res = await giftService.get();
    if (res && res.data && res.data.success) {
      setSent(res.data.data.sent);
      setReceive(res.data.data.receive);
    }
    setIsLoadingData(false);
  };

  /**
   * Thay giá trị input
   * @param {event} e event của input
   */
  const onChangeInput = (e) => {
    if (!isLoading) {
      setNewGift((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  /**
   * Xử lý dữ liệu trước khi lưu
   * @returns dữ liệu sau khi xử lý
   */
  const handleDataBeforSave = () => {
    const giftData = JSON.parse(JSON.stringify(newGift));
    if (editMode === 1) {
      giftData.sender = user._id;
      if (user._id === couple.boy._id) {
        giftData.receiver = couple.girl._id;
      } else {
        giftData.receiver = couple.boy._id;
      }
      giftData.seen = false;
    }
    var sendDate = new Date(giftData.sendDate);
    sendDate.setHours(0, 0, 0, 0);
    giftData.sendDate = sendDate;
    return giftData;
  };

  /**
   * Validate dữ liệu trước khi lưu
   * @returns true - nếu dữ liệu hợp lệ, false - nếu dữ liệu không hợp lệ
   */
  const validateDataBeforSave = () => {
    if (!newGift.title || !newGift.title.trim()) {
      alert('Tiêu đề không được để trống.');
      return false;
    }
    if (!newGift.content || !newGift.content.trim()) {
      alert('Nội dung không được để trống.');
      return false;
    }
    return true;
  };

  /**
   * Sự kiện bấm nút lưu
   */
  const submitGift = async () => {
    setIsLoading(true);
    if (validateDataBeforSave()) {
      const data = handleDataBeforSave();
      await saveGift(data);
      setShowForm(false);
    }
    setIsLoading(false);
  };

  /**
   * Xem lời chúc
   * @param {*} gift lời chúc
   */
  const displayGift = (gift) => {
    setSelectedGift(gift);
    setShowGift(true);
  };

  /**
   * Sự kiện ấn vào lời chúc đã nhận
   * @param {*} gift lời chúc
   */
  const openGift = async (gift) => {
    if (!gift.seen) {
      await seenGift(gift._id);
    }
    displayGift(gift);
  };

  /**
   * Cập nhật giá trị seen của lời chúc
   * @param {*} id id lời chúc
   */
  const seenGift = async (id) => {
    const res = await giftService.put({ _id: id, seen: true });
    if (res && res.data && res.data.success) {
      dispatch(setNotSeen(notSeen - 1 > 0 ? notSeen - 1 : 0));
      setReceive((prev) => {
        return prev.map((e) => {
          if (e._id === id) {
            e.seen = true;
          }
          return e;
        });
      });
    }
  };

  /**
   * Xóa lời chúc
   * @param {*} e event của nút xóa
   * @param {*} gift lời chúc
   */
  const deleteGift = async (e, gift) => {
    e.stopPropagation();
    const result = window.confirm('Bạn có chắc muốn xóa lời chúc này không?');
    if (result) {
      const res = await giftService.delete(gift._id);
      if (res && res.data && res.data.success) {
        setSent((prev) => {
          return prev.filter((e) => e._id !== gift._id);
        });
      } else {
        alert('Xóa lời chúc thất bại, vui lòng thử lại.');
      }
    }
  };

  /**
   * Mở form cập nhật lời chúc
   * @param {*} gift lời chúc
   */
  const updateGift = async (gift) => {
    setEditMode(2);
    const clone = JSON.parse(JSON.stringify(gift));
    clone.sendDate = moment(new Date(clone.sendDate)).format('YYYY-MM-DD');
    setNewGift(clone);
    setShowForm(true);
  };

  /**
   * Lưu lời chúc
   * @param {*} gift lời chúc
   */
  const saveGift = async (gift) => {
    if (editMode === 1) {
      const res = await giftService.post(gift);
      if (res && res.data && res.data.success) {
        setSent((prev) => {
          return [res.data.data, ...prev];
        });
      } else {
        alert('Thêm lời chúc thất bại, vui lòng thử lại.');
      }
    } else {
      const res = await giftService.put(gift);
      if (res && res.data && res.data.success) {
        setSent((prev) => {
          return prev.map((e) => {
            if (e._id === res.data.data._id) {
              return res.data.data;
            }
            return e;
          });
        });
      } else {
        alert('Sửa lời chúc thất bại, vui lòng thử lại.');
      }
    }
    setNewGift({
      title: '',
      content: '',
      signature: '',
      sendDate: moment(new Date()).format('YYYY-MM-DD'),
    });
  };

  const updateHeart = async (id) => {
    const res = await giftService.updateHeart(id);
    if (res && res.data && res.data.success) {
      setReceive((prev) => {
        return prev.map((e) => {
          if (e._id === id) {
            return res.data.data;
          }
          return e;
        });
      });
    } else {
      alert('Thả tim thất bại, vui lòng thử lại.');
    }
  };
  //#endregion

  return (
    <div id='wrapper'>
      <div className='gift-title'>
        <div className='gift-title-text'>Lời chúc</div>
      </div>
      <div className='tab-label'>
        <div
          className={`tab-label-item tab-label-item-1 ${
            tab === 1 ? 'active' : ''
          }`}
          onClick={() => setTab(1)}
        >
          Đã nhận
        </div>
        <div
          className={`tab-label-item tab-label-item-2 ${
            tab === 2 ? 'active' : ''
          }`}
          onClick={() => setTab(2)}
        >
          Đã gửi
        </div>
      </div>
      <div className='tab-content-wrapper'>
        {tab === 1 && (
          <div className='tab-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            <ReceiveGift
              receive={receive}
              isLoading={isLoadingData}
              openGift={openGift}
              updateHeart={updateHeart}
            />
          </div>
        )}
        {tab === 2 && (
          <div className='tab-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            <SentGift
              sent={sent}
              isLoading={isLoadingData}
              updateGift={updateGift}
              deleteGift={deleteGift}
            />
          </div>
        )}
      </div>
      {showForm ? (
        <GiftForm
          newGift={newGift}
          editMode={editMode}
          isLoading={isLoading}
          onChangeInput={onChangeInput}
          setShowForm={setShowForm}
          setNewGift={setNewGift}
          submitGift={submitGift}
        />
      ) : null}
      {showGift ? (
        <DisplayGift selectedGift={selectedGift} setShowGift={setShowGift} />
      ) : null}
      <div
        className='button-add'
        onClick={() => {
          setNewGift({
            title: '',
            content: '',
            signature: '',
            sendDate: moment(new Date()).format('YYYY-MM-DD'),
          });
          setEditMode(1);
          setShowForm(true);
        }}
      >
        <Icon icon='tabler:plus' color='#ff91af' />
      </div>
    </div>
  );
};

export default GiftPage;
