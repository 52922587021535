import React, { useEffect, useState } from 'react';
import OptionButton from './OptionButton';
import SideBar from './SideBar';
import giftService from '../../services/giftService';
import { useDispatch, useSelector } from 'react-redux';
import { giftSelector, setNotSeen } from '../../store/reducers/giftSlice';
// import { NewGift } from '../common';

const MainLayout = ({ children }) => {
  //#region Hooks
  const [showSideBar, setShowSideBar] = useState(false);
  // const [newGift, setNewGift] = useState(null);

  const { notSeen } = useSelector(giftSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    getGift();
  }, []);
  //#endregion

  //#region Methods/Functions
  const getGift = async () => {
    const res = await giftService.get();
    if (res && res.data && res.data.success) {
      const notSeenList = res.data.data.receive.filter((item) => !item.seen);
      dispatch(setNotSeen(notSeenList.length));
    }
  };
  //#endregion

  return (
    <div>
      <OptionButton notSeen={notSeen} onClick={() => setShowSideBar(true)} />
      <SideBar
        notSeenGift={notSeen}
        showSideBar={showSideBar}
        onClose={() => setShowSideBar(false)}
      />
      <div>{children}</div>
      {/* {newGift ? (
        <NewGift gift={newGift} onClose={() => setNewGift(null)} />
      ) : null} */}
    </div>
  );
};

export default MainLayout;
