import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

const SettingImage = ({ filePreview, handleCancel, handleSave }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // để tránh lỗi CORS
      image.src = url;
    });

  const getCroppedImg = async () => {
    const image = await createImage(filePreview);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;

    ctx.drawImage(
      image,
      croppedArea.x,
      croppedArea.y,
      croppedArea.width,
      croppedArea.height,
      0,
      0,
      croppedArea.width,
      croppedArea.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = 'croppedAvatar.png';
        resolve(file);
      }, 'image/png');
    });
  };

  return (
    <>
      <span className='font-semibold text-lg mb-3 text-center'>
        Thông tin cá nhân
      </span>
      <div className='relative h-96 mt-4'>
        <Cropper
          image={filePreview}
          aspect={1 / 1}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: '360px',
              height: '360px',
              backgroundColor: '#fff',
              borderRadius: '100rem',
            },
          }}
          cropSize={{ width: 360, height: 360 }}
          objectFit='cover'
          showGrid={false}
        />
      </div>
      <div class='button-group flex'>
        <button class='btn-cancel w-1/2 me-1' onClick={handleCancel}>
          Hủy bỏ
        </button>
        <button
          className='btn-save w-1/2 ms-1'
          onClick={async () => {
            const avt = await getCroppedImg();
            handleSave(avt);
          }}
        >
          Lưu
        </button>
      </div>
    </>
  );
};

SettingImage.propTypes = {
  filePreview: PropTypes.object,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
};

export default SettingImage;
