import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react/dist/iconify.js';

const OptionButton = ({ notSeen, onClick }) => {
  return (
    <div className='right-buttons'>
      <div className='right-button' onClick={onClick}>
        <Icon icon='fluent:line-horizontal-3-20-filled' color='#ffccd5' />
        {notSeen > 0 && <div className='not-seen'>{notSeen}</div>}
      </div>
    </div>
  );
};

OptionButton.propTypes = { notSeen: PropTypes.number, onClick: PropTypes.func };

export default OptionButton;
