import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react/dist/iconify.js';

const GiftForm = ({
  newGift,
  editMode,
  isLoading,
  onChangeInput,
  setShowForm,
  setNewGift,
  submitGift,
}) => {
  return (
    <div className='gift-create'>
      <div className='outside' onClick={() => setShowForm(false)}></div>
      <div className='gift-create-content w-80 md:w-1/2 lg:w-1/3'>
        <div className='gift-create-text'>
          {editMode === 1 ? 'Thêm lời chúc' : 'Sửa lời chúc'}
        </div>
        <input
          type='text'
          name='title'
          value={newGift.title}
          placeholder='Tiêu đề'
          onChange={onChangeInput}
        />
        <textarea
          rows='4'
          placeholder='Nội dung'
          name='content'
          value={newGift.content}
          onChange={onChangeInput}
        ></textarea>
        <input
          type='text'
          name='signature'
          value={newGift.signature}
          placeholder='Chữ ký'
          onChange={onChangeInput}
        />
        <input
          type='date'
          name='sendDate'
          value={newGift.sendDate}
          onChange={onChangeInput}
          placeholder='Thời gian gửi'
        />
        <div className='button-group'>
          <button
            className='button-cancel'
            onClick={() => {
              setShowForm(false);
              setNewGift({
                title: '',
                content: '',
                signature: '',
                sendDate: moment(new Date()).format('YYYY-MM-DD'),
              });
            }}
          >
            Hủy bỏ
          </button>
          {/* <button onClick={submitGift}>Lưu</button> */}
          <button
            className={`btn-save ${isLoading ? 'btn-loading' : ''}`}
            onClick={submitGift}
          >
            {!isLoading ? (
              'Lưu'
            ) : (
              <Icon
                icon='line-md:loading-loop'
                width='1.75rem'
                height='1.75rem'
              />
            )}
          </button>
        </div>
      </div>
      {isLoading && (
        <div className='loader-wrapper'>
          <span className='loader'></span>
        </div>
      )}
    </div>
  );
};

GiftForm.propTypes = {
  newGift: PropTypes.object,
  editMode: PropTypes.number,
  isLoading: PropTypes.bool,
  onChangeInput: PropTypes.func,
  setShowForm: PropTypes.func,
  setNewGift: PropTypes.func,
  submitGift: PropTypes.func,
};

export default GiftForm;
