import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from './firebase';

/**
 * Upload ảnh lên firebase
 * @param {*} file
 * @param {*} folder
 * @returns
 */
export const uploadFileToFirebase = async (file, folder) => {
  // Tạo file trên firebase
  const fileUrl = `${folder}/${new Date().getTime()}_${file.name}`;

  const fileRef = ref(storage, fileUrl);

  // Upload
  const uploadRes = await uploadBytesResumable(fileRef, file);
  const fileLink = await getDownloadURL(uploadRes.ref);

  return fileLink;
};
