import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react/dist/iconify.js';

const ImageView = ({ img, handleClose, handleDeleteImg }) => {
  return (
    <div className='preview-image'>
      <img className='preview-img md:mt-4' src={img.image} alt='Preview' />
      <div className='image-caption-view'>{img.caption}</div>
      <div className='preview-buttons'>
        <button className='button-edit text-3xl'>
          <Icon icon='mdi:heart' color='#ff91af' />
        </button>
        <button className='button-upload text-6xl' onClick={handleClose}>
          <Icon icon='ic:round-close' color='#ff91af' />
        </button>
        <button
          className='button-cancel text-3xl'
          onClick={() => handleDeleteImg(img._id)}
        >
          <Icon icon='fluent:delete-28-filled' color='#ff91af' />
        </button>
      </div>
    </div>
  );
};

ImageView.propTypes = {
  img: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ImageView;
