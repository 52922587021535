import {
  LoginPage,
  HomePage,
  GiftPage,
  MusicPage,
  ImagePage,
  SettingPage,
} from '../pages';

const authRoutes = [{ path: '/login', component: LoginPage }];

const routes = [
  { path: '/', component: HomePage },
  { path: '/gift', component: GiftPage },
  { path: '/music', component: MusicPage },
  { path: '/image', component: ImagePage },
  { path: '/setting', component: SettingPage },
  { path: '*', component: HomePage },
];

export { authRoutes, routes };
