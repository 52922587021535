import React from 'react';

const LoginBubbles = () => {
  return (
    <div className='login-bubbles'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoginBubbles;
