import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react/dist/iconify.js';

const ReceiveGiftItem = ({ item, openGift, updateHeart }) => {
  return (
    <div
      className={`tab-content-item ${item.seen ? 'seen' : ''}`}
      onClick={() => openGift(item)}
    >
      <div
        className='tab-content-item-icon'
        onClick={(e) => {
          e.stopPropagation();
          updateHeart(item._id);
        }}
      >
        <Icon icon='mdi:heart' color={item.heart ? '#ff91af' : '#fff'} />
        <span>
          {item.heart && item.heart > 1 ? <span>{item.heart}</span> : null}
        </span>
      </div>
      <div className='tab-content-item-title'>
        <span className='title'>{item.title}</span>
        <span className='send-date'>
          {moment(item.sendDate).format('DD/MM/YYYY')}
        </span>
      </div>
      {!item.seen && <div className='tab-content-item-seen'></div>}
    </div>
  );
};

ReceiveGiftItem.propTypes = {
  item: PropTypes.object,
  openGift: PropTypes.func,
  updateHeart: PropTypes.func,
};

export default ReceiveGiftItem;
