import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../store/reducers/userSlice';
import { useEffect } from 'react';
import httpService from './httpService';

const HttpInterceptor = ({ children }) => {
  const { token } = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const req = httpService.interceptors.request.use((config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    return () => {
      httpService.interceptors.request.eject(req);
    };
  }, [dispatch, token]);

  return children;
};

export default HttpInterceptor;
