import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isAuthenticate: false,
    appPassword: '2902',
  },
  reducers: {
    setIsAuthenticate(state, action) {
      state.isAuthenticate = action.payload;
    },
  },
});

const appReducer = appSlice.reducer;

export const { setIsAuthenticate } = appSlice.actions;

export const appSelector = (state) => state.appReducer;

export default appReducer;
