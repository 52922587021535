import { Icon } from '@iconify/react/dist/iconify.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ImageSlide = ({ lstImage }) => {
  const [index, setIndex] = useState(0);
  const [time, setTime] = useState(null);
  const [isPause, setIsPause] = useState(false);
  const [range, setRange] = useState(3000);

  useEffect(() => {
    if (!isPause) {
      const timeout = setTimeout(() => {
        setIndex((prev) => (prev + 1) % lstImage.length);
      }, [range]);
      setTime(timeout);

      return () => clearTimeout(timeout);
    }
  }, [index, lstImage, isPause, range]);

  return lstImage && lstImage.length > 0 ? (
    <div className='preview-image'>
      <img
        className='preview-img md:mt-4'
        src={lstImage[index].image}
        alt='Preview'
      />
      <div className='image-caption-view'>{lstImage[index].caption}</div>
      <div className='preview-buttons'>
        <button
          className='button-edit text-3xl'
          onClick={() => {
            if (index === 0) {
              setIndex(lstImage.length - 1);
            } else {
              setIndex(index - 1);
            }
          }}
        >
          <Icon icon='tabler:player-track-prev-filled' color='#ff91af' />
        </button>
        <button
          className='button-upload text-5xl'
          onClick={() => {
            if (time) {
              clearTimeout(time);
              setTime(null);
              setIsPause(true);
            } else {
              setIsPause(false);
            }
          }}
        >
          <Icon
            icon={isPause ? 'fluent:play-28-filled' : 'fluent:pause-28-filled'}
            color='#ff91af'
          />
        </button>
        <button
          className='button-cancel text-3xl'
          onClick={() => {
            if (index === lstImage.length - 1) {
              setIndex(0);
            } else {
              setIndex(index + 1);
            }
          }}
        >
          <Icon icon='tabler:player-track-next-filled' color='#ff91af' />
        </button>
      </div>
      <div className='slider-container'>
        <input
          type='range'
          className='slider'
          min={1000}
          max={5000}
          value={range}
          onChange={(e) => setRange(e.target.value)}
        />
      </div>
    </div>
  ) : null;
};

ImageSlide.propTypes = {
  lstImage: PropTypes.array,
};

export default ImageSlide;
