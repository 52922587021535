import React from 'react';
import ReceiveGiftItem from './ReceiveGiftItem';
import PropTypes from 'prop-types';
import { Skeleton } from '../common';

const ReceiveGift = ({ receive, openGift, updateHeart, isLoading }) => {
  return isLoading
    ? [0, 1, 2, 3].map((i) => {
        return (
          <div className='tab-content-item seen' key={i}>
            <Skeleton
              cls='me-2'
              customStyle={{ width: 45, height: 45, borderRadius: '0.5rem' }}
            />
            <div className='flex flex-col flex-1'>
              <Skeleton
                customStyle={{
                  width: '100%',
                  height: 18,
                  borderRadius: '0.25rem',
                }}
              />
              <Skeleton
                cls='mt-2'
                customStyle={{
                  width: 100,
                  height: 18,
                  borderRadius: '0.25rem',
                }}
              />
            </div>
          </div>
        );
      })
    : receive && receive.length > 0
    ? receive.map((item) => {
        return (
          <ReceiveGiftItem
            item={item}
            openGift={openGift}
            key={item._id}
            updateHeart={updateHeart}
          />
        );
      })
    : null;
};

ReceiveGift.propTypes = {
  receive: PropTypes.array,
  openGift: PropTypes.func,
  updateHeart: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ReceiveGift;
