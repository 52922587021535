import BaseService from './baseService';

class ImageService extends BaseService {
  url = '/image';

  get() {
    return this.httpService.get(this.url);
  }

  post(data) {
    return this.httpService.post(this.url, data);
  }

  put(data) {
    return this.httpService.put(this.url, data);
  }

  delete(id) {
    return this.httpService.delete(this.url + '/' + id);
  }
}

export default new ImageService();
