import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/appSlice';
import userReducer from './reducers/userSlice';
import giftReducer from './reducers/giftSlice';

const store = configureStore({
  reducer: {
    appReducer,
    userReducer,
    giftReducer,
  },
});

export default store;
