import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from './Skeleton';

const LazyImage = ({ src, alt, cls, loadingCls, width, height }) => {
  return (
    <div className='image-container'>
      <LazyLoadImage
        src={src}
        alt={alt}
        width={width}
        height={height}
        effect='blur'
        placeholderSrc='/path-to-placeholder.jpg' // Đường dẫn đến ảnh placeholder
        className={cls}
      />
      <div className={loadingCls}>
        <Skeleton
          customStyle={{ width: '100%', height: '100%', borderRadius: '1rem' }}
        />
      </div>
    </div>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  cls: PropTypes.string,
  loadingCls: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LazyImage;
