import React from 'react';
import PropTypes from 'prop-types';

const Skeleton = ({ customStyle, cls }) => {
  return <div className={`skeleton ${cls}`} style={customStyle}></div>;
};

Skeleton.propTypes = {
  customStyle: PropTypes.object,
};

export default Skeleton;
