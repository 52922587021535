import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react/dist/iconify.js';

const SentGiftItem = ({ item, updateGift, deleteGift }) => {
  return (
    <div className='tab-content-item seen' onClick={() => updateGift(item)}>
      <div className='tab-content-item-icon'>
        <Icon icon='mdi:heart' color='#ff91af' />
        <span>{item.heart ? item.heart : 0}</span>
      </div>
      <div className='tab-content-item-title'>
        <span className='title'>{item.title}</span>
        <span className='send-date'>
          {moment(item.sendDate).format('DD/MM/YYYY')}
        </span>
      </div>
      <div
        className='btn-option'
        onClick={(e) => {
          deleteGift(e, item);
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1em'
          height='1em'
          viewBox='0 0 12 12'
        >
          <path
            fill='#f8c8c8'
            d='M5 3h2a1 1 0 0 0-2 0M4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zM5 5.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0V6a.5.5 0 0 0-.5-.5'
          ></path>
        </svg>
      </div>
    </div>
  );
};

SentGiftItem.propTypes = {
  item: PropTypes.object,
  updateGift: PropTypes.func,
  deleteGift: PropTypes.func,
};

export default SentGiftItem;
