import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useEffect, useState } from 'react';

import { loginSuccess, userSelector } from '../store/reducers/userSlice';
import { LoginBubbles, LoginPasscode, LoginUser } from '../components';
import authService from '../services/authService';

const LoginPage = () => {
  // State
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState([null, null, null, null]);
  const [dotCls, setDotCls] = useState('dot');
  const [isLoading, setIsLoading] = useState(false);

  // Selector
  const { isAuthenticate } = useSelector(userSelector);

  // Dispatch
  const dispatch = useDispatch();

  // Effect
  useEffect(() => {
    let PIN = '';
    password.forEach((n) => {
      if (n || n === 0) {
        PIN = PIN + n.toString();
      }
    });
    if (PIN.length === 4) {
      // validatePassword(PIN);
      login(PIN);
      setPassword([null, null, null, null]);
    }
  }, [password]);

  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username) {
      setUsername(username);
      setStep(2);
    }
  }, []);

  // Function
  /**
   * Xử lý sự kiện ấn nút số
   * @param {*} n
   */
  const handleClickNumber = (n) => {
    if (!isLoading) {
      setPassword((prev) => {
        let isFirst = true;
        prev = prev.map((item, i) => {
          if (!item && isFirst && item !== 0) {
            isFirst = false;
            return n;
          }
          return item;
        });

        return prev;
      });
    }
  };

  /**
   * Xử lý sự kiện clear số
   */
  const handleClear = () => {
    if (!isLoading) {
      setPassword([null, null, null, null]);
    }
  };

  /**
   * Sự kiện đăng nhập
   * @param {*} passcode
   */
  const login = async (passcode) => {
    setIsLoading(true);
    localStorage.setItem('username', username);
    const res = await authService.login({
      username: username,
      passcode: passcode,
    });

    if (res && res.data && res.data.success) {
      dispatch(loginSuccess(res.data.data));
    } else {
      setDotCls('dot-false');
      setTimeout(() => {
        setDotCls('dot');
      }, 500);
    }
    setIsLoading(false);
  };

  return isAuthenticate ? (
    <Navigate to='/' />
  ) : (
    <div id='wrapper'>
      {step === 1 ? (
        <LoginUser
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onNext={() => setStep(2)}
        />
      ) : (
        <LoginPasscode
          password={password}
          dotCls={dotCls}
          onClickNumber={handleClickNumber}
          onClear={handleClear}
          onBack={() => setStep(1)}
          isLoading={isLoading}
        />
      )}
      <LoginBubbles />
    </div>
  );
};

export default LoginPage;
