import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticate: false,
    token: null,
    user: null,
    couple: null,
  },
  reducers: {
    loginSuccess(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.couple = action.payload.couple;
      state.isAuthenticate = true;
    },
    logout(state, action) {
      state.isAuthenticate = false;
      state.token = null;
      state.user = null;
      state.couple = null;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateBoy(state, action) {
      state.couple.boy = action.payload;
    },
    updateGirl(state, action) {
      state.couple.girl = action.payload;
    },
    updateCouple(state, action) {
      state.couple.message = action.payload.message;
      state.couple.loveDate = action.payload.loveDate;
    },
  },
});

const userReducer = userSlice.reducer;

export const {
  loginSuccess,
  logout,
  updateUser,
  updateBoy,
  updateGirl,
  updateCouple,
} = userSlice.actions;

export const userSelector = (state) => state.userReducer;

export default userReducer;
