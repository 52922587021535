import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Icon } from '@iconify/react/dist/iconify.js';

const Info = ({ couple }) => {
  const [zodiac, setZodiac] = useState(null);

  const findZodiacSign = (month, day) => {
    if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
      return {
        name: 'Bảo Bình',
        icon: 'game-icons:aquarius',
        color: '#93c7cc',
      };
    } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
      return {
        name: 'Bảo Bình',
        icon: 'game-icons:pisces',
        color: '#9a4db3',
      };
    } else if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
      return {
        name: 'Bạch Dương',
        icon: 'game-icons:aries',
        color: '#e76479',
      };
    } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
      return {
        name: 'Kim Ngưu',
        icon: 'game-icons:taurus',
        color: '#4b51be',
      };
    } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
      return {
        name: 'Song Tử',
        icon: 'game-icons:gemini',
        color: '#fbda7c',
      };
    } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
      return {
        name: 'Cự Giải',
        icon: 'game-icons:cancer',
        color: '#8b7494',
      };
    } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
      return {
        name: 'Sư Tử',
        icon: 'game-icons:leo',
        color: '#e9bb97',
      };
    } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
      return {
        name: 'Xử Nữ',
        icon: 'game-icons:virgo',
        color: '#99bb80',
      };
    } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
      return {
        name: 'Thiên Bình',
        icon: 'game-icons:libra',
        color: '#6eb7df',
      };
    } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
      return {
        name: 'Bọ Cạp',
        icon: 'game-icons:scorpio',
        color: '#db7ccd',
      };
    } else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
      return {
        name: 'Nhân Mã',
        icon: 'game-icons:sagittarius',
        color: '#997ec6',
      };
    } else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
      return {
        name: 'Ma Kết',
        icon: 'game-icons:capricorn',
        color: '#c77587',
      };
    }
  };

  const getPercentBirthday = (dateOfBirth) => {
    const date = new Date(dateOfBirth);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const birthdayInYear = new Date(
      today.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    birthdayInYear.setHours(0, 0, 0, 0);
    let lastBirthday = new Date(),
      nextBirthday = new Date();

    if (birthdayInYear >= today) {
      lastBirthday = new Date(
        today.getFullYear() - 1,
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0
      );
      nextBirthday = birthdayInYear;
    } else {
      lastBirthday = birthdayInYear;
      nextBirthday = new Date(
        today.getFullYear() + 1,
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0
      );
    }
    return ((today - lastBirthday) / (nextBirthday - lastBirthday)) * 100;
  };

  const getAge = (dateOfBirth) => {
    let birthday = new Date(dateOfBirth),
      today = new Date(),
      birthdayInYear = new Date(
        today.getFullYear(),
        birthday.getMonth(),
        birthday.getDate(),
        0,
        0,
        0,
        0
      );

    birthday.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (birthdayInYear > today) {
      return today.getFullYear() - birthday.getFullYear() - 1;
    } else {
      return today.getFullYear() - birthday.getFullYear();
    }
  };

  useEffect(() => {
    setZodiac({
      boy: findZodiacSign(
        new Date(couple.boy.dateOfBirth).getMonth() + 1,
        new Date(couple.boy.dateOfBirth).getDate()
      ),
      girl: findZodiacSign(
        new Date(couple.girl.dateOfBirth).getMonth() + 1,
        new Date(couple.girl.dateOfBirth).getDate()
      ),
    });
  }, [couple]);

  return couple ? (
    <div id='info'>
      <div className='one'>
        <div className='avt'>
          <img src={couple.boy.avatar} alt='boy' loading='lazy' />
          <CircularProgressbar
            className='circular'
            value={getPercentBirthday(couple.boy.dateOfBirth)}
            styles={{
              path: {
                stroke: '#ff91af',
                strokeWidth: 3,
              },
              background: {
                fill: '#3e98c7',
              },
              trail: {
                stroke: 'transparent',
              },
            }}
          />
        </div>

        <p id='name'>{couple.boy.fullName}</p>
        <div className='flex'>
          <div className='boy-age flex items-center me-1'>
            <Icon icon='mdi:gender-male' className='me-0.5' />
            <span>{getAge(couple.boy.dateOfBirth)}</span>
          </div>
          {zodiac && (
            <p
              className='zodiac flex items-center'
              style={{ backgroundColor: zodiac.boy.color }}
            >
              {zodiac.boy.name}
              <Icon icon={zodiac.boy.icon} className='ms-0.5' />
            </p>
          )}
        </div>
      </div>
      <div className='add-heart mx-6 mb-16 md:mx-32'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className='two'>
        <div className='avt'>
          <img src={couple.girl.avatar} alt='girl' loading='lazy' />
          <CircularProgressbar
            className='circular'
            value={getPercentBirthday(couple.girl.dateOfBirth)}
            styles={{
              path: {
                stroke: '#ff91af',
                strokeWidth: 3,
              },
              background: {
                fill: '#3e98c7',
              },
              trail: {
                stroke: 'transparent',
              },
            }}
          />
        </div>
        <p id='name'>{couple.girl.fullName}</p>
        <div className='flex'>
          <div className='girl-age flex items-center me-1'>
            <Icon icon='mdi:gender-female' className='me-0.5' />
            <span>{getAge(couple.girl.dateOfBirth)}</span>
          </div>
          {zodiac && (
            <p
              className='zodiac flex items-center'
              style={{ backgroundColor: zodiac.girl.color }}
            >
              {zodiac.girl.name}
              <Icon icon={zodiac.girl.icon} className='ms-0.5' />
            </p>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

Info.propTypes = {
  couple: PropTypes.object,
};

export default Info;
