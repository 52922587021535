import BaseService from './baseService';

class UserService extends BaseService {
  updateUrl = '/user';

  updateCoupleUrl = '/user/couple';

  /**
   * Service cập nhật thông tin user
   * @param {*} data
   * @returns
   */
  update(data) {
    return this.httpService.put(this.updateUrl, data);
  }

  /**
   * Service cập nhật thông tin cặp đôi
   */
  updateCouple(data) {
    return this.httpService.put(this.updateCoupleUrl, data);
  }
}

export default new UserService();
